import Vue from "vue";
import VueRouter from 'vue-router'
import _import from "./import";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: _import('/home')
  },
  {
    path: '/shopping',
    component: _import('/shopping'),
    meta:{
      title:"笑萍购",
      keepAlive: true,
    }
  },
  {
    path: '/shopping/mine',
    component: _import('/shopping/mine'),
    meta:{
      title:"笑萍购"
    }
  },
  {
    path: '/robot',
    component: _import('/robot'),
    meta:{
      title:"笑萍购"
    }
  },
  {
    path: '/smzdm/task',
    component: _import('/smzdm/task')
  },
  {
    path: '/shopping/goods/add',
    component: _import('/shopping/goods/add'),
    meta:{
      title:"笑萍购"
    }
  },
  {
    path: '/shopping/help',
    component: _import('/shopping/mine/help'),
  },
  {
    path: '/shopping/order',
    component: _import('/shopping/mine/order'),
  },
  {
    path: '/web/profile',
    component: _import('/web/profile'),
  },
  {
    path: '/web/robot',
    component: _import('/web/robot'),
  },
  {
    path: '/web/shareping',
    component: _import('/web/shareping'),
  }
]

const router = new VueRouter({
  routes
})

// router.afterEach((to,from) => {
//   if(to.meta.title){
//     document.title = to.meta.title
//   }else{
//     document.title = "笑萍生活"
//   }
// })

export default router