import Vue from 'vue'
import App from './App.vue'
import router from "./router/index.js";
import axios from 'axios'
import 'vant/lib/index.css';
import Vant from 'vant';
import VueClipboard from 'vue-clipboard2';
import VConsole from 'vconsole';
import global from "@/utils/global.js";
import store from './store/index.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

Vue.config.productionTip = false
const myAppConfig = window.appConfig
if (myAppConfig.isShowConsole) {
  new VConsole();
}
Vue.use(Vant);
Vue.use(VueClipboard);
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

Vue.prototype.$ajax=axios;
Vue.prototype.global= global;

